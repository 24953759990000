module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"d934e776-89cf-4f8c-8ace-08b844d98d5b","guideId":"305","siteUrl":"https://www.blekingeleden.com","lang":"sv","typesCategories":{"hiking":["33","109","116","7"]},"typesCategoriesIncluded":{"hiking":["33","109","116","7"]},"imagePlaceholderColor":"#006e6e","templates":{"type":"/app/src/templates/type.js","site":"/app/src/templates/site.js","page":"/app/src/templates/page.js","articles":"/app/src/templates/articles.js","articlesPost":"/app/src/templates/articles-post.js","news":"/app/src/templates/news.js","newsPost":"/app/src/templates/news-post.js","events":"/app/src/templates/events.js","eventsPost":"/app/src/templates/events-post.js","search":"/app/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Blekingeleden","short_name":"Blekingeleden","description":"Blekinges längsta vandringsled rakt genom Sveriges trädgård","theme_color":"#005096","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
